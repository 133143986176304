* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Ascender Serif";
}

.navmain {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1px 1px;

}

.navleft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11%;
    object-fit: fill;
    height: 9vw;
}

.navleft .logo {
    height: 8.8vw;
    width: 11.7vw;
    margin-bottom: 1px;

}

.navleft .logo img {
    margin-top: 5px;
    margin-left: 20px;
    height: 100%;
    width: 100%;
    /* background-color: red; */
}

.navmid {
    color: "#111A5C";
    text-align: center;
    width: 65%;
}



.socialmedia {
    width: 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}

.socialmedia a {
    font-size: 2vmax;
    /* background-color: red; */
}

.navright {
    /* width: 15vw; */
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* background-color: red; */
}

.seacrch {
    display: flex;


}

.seacrch .btn {
    color: rgb(255, 255, 255);
    border-radius: 0px;
    border: none;
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
}

.aa {
    border: white;
    height: 30px;
}


.nextnavbar {
    font-size: 1.3vmax;
    color: #313C74;
}

.loginbtn {
    color: white;
    background-color: #EF4444;
}

.nextnavbar .loginbtn {
    color: white;
}

.nextnavbar .loginbtn:hover {
    background-color: #EF4444;

}

.navbar-toggler {
    border: none;

}

.navbar-toggler:focus {
    outline: none;

}

#firsth {
    font-size: 4vw;
}

.navmid h1 {
    font-size: 3vw;
}

@media screen and (max-width:768px) {
    .navmain {
        flex-direction: column;
        padding: 13px 0px 18px 0px;

    }

    .navleft {
        width: 100%;
        margin-bottom: 8px;
    }

    .navmid {
        width: 100%;
    }

    .navright {
        width: 100%;
    }

    .socialmedia {
        width: 40vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }

    .socialmedia i {
        font-size: 40px;
    }

    .nav-link {
        font-size: 15px;
        color: #2F3A72;
        font-weight: bold;
    }

    .navtoggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .navleft .logo img {
        margin-top: 5px;
        margin-left: 0px;
  
        /* background-color: red; */
    }

    .navleft .logo {
        height: auto;
        width: 11vw;
    }

    .navmid h1 {
        font-size: 20px;
    }

    #firsth {
        font-size: 28px;
    }

    .aa {
        height: 30px;

    }

    .seacrch .btn {
        height: 30px;
        font-size: 17px;
    }

    .socialmedia i {
        font-size: 30px;
    }

    .navmid p {
        font-size: 12px;
    }

    .socialmedia {
        width: 30vw;
    }
}




@media screen and (max-width:500px) {
    .navmain {
        flex-direction: column;
        padding: 13px 0px 18px 0px;
    }

    .navleft {
        width: 100%;
        margin-bottom: 8px;
    }

    .navmid {
        width: 100%;
    }
 
    .navright {
        width: 100%;
    }

    .socialmedia {
        width: 40vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }
  
    .socialmedia i {
        font-size: 40px;
    }

    .nav-link {
        font-size: 15px;
        color: #2F3A72;
        font-weight: bold;
    }

    .navtoggle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .navleft .logo {
        height: auto;
        width: 10vw;
    }

    .navmid h1 {
        font-size: 15px;
    }

    #firsth {
        font-size: 23px;
    }

    .aa {
        height: 30px;

    }

    .seacrch .btn {
        height: 30px;
        font-size: 15px;
    }

    .socialmedia i {
        font-size: 30px;
    }

    .navmid p {
        font-size: 7.5px;
    }

    .socialmedia {
        width: 30vw;
    }
}

@media screen and (max-width:400px) {

    .socialmedia{
        margin-right: 40px;
    }
}