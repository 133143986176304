.contactt {
    width: 30%;
    text-align: center;
    z-index: 999;
    padding: 20px 50px;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    background-color: #C0D1D7;
    color: #1D4974;
}

.ssss {
    margin-top: 40px;
}

.form-group {
    margin: 20px 10px;
    color: black;
}

.form-group label {
    font-size: 20px;
}

.form-group input {
    height: 50px;
}




.latest_card_box_ad {
    width: 100%;
    display: flex;
    align-items: center;
}


.contactleft {
    width: 50%;
    /* height: 500px; */
    /* background-color:#fafbfc; */
    color: white;
    padding: 20px 20px;
}

.mnc {
    text-decoration: none;
    font-size: 17px;

}



.contactright {
    width: 50%;
    color: rgb(14, 13, 13);
    position: relative;
    padding: 20px 20px;


}

.bbb {
    color: black;
    font-size: 17px;

}


.admcon {
    background-color: rgb(29, 71, 118);
    color: white;
    font-size: 1.2vmax;
    padding: 10px 50px;

}

.map {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}

.map iframe {
    height: 100%;
    width: 100%;

}



.emailus {
    max-width: 660px;
    /* Set a maximum width for the form */
    width: 100%;
    /* Ensure the form takes up 100% of the parent container */
    margin: 0 auto;
    /* Center the form horizontally */
    padding: 20px;
    /* Add some padding for spacing */
}

.emailus h5 {
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.emailus h5 span {
    font-size: 2rem;
}

.form-control {
    width: 100%;
    /* Ensure the input fields take up full width within the form */
}

@media screen and (max-width:768px) {
    .latest_card_box_ad {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contactleft {
        width: 100%;
    }

    .contactright {
        width: 100%;
        color: white;
        margin-top: 150px;
    }



    .bbb {
        color: black;
        font-size: 13px;

    }

    .mnc {
        font-size: 13px;
    }

    .admcon {
        font-size: 15px;
        padding: 5px 20px;
    }

    .form-group label {
        font-size: 15px;
    }

    .form-group input {
        height: 40px;
    }

    .contactt {
        width: 80%;
        padding: 10px 10px;
        word-wrap: normal;
        align-items: center;
        font-size: 15px;

    }

    .contactt h1 {
        font-size: 18px;
    }

    .ssss {
        color: black;
        margin-top: 10px;
        font-size: 18px;

    }

    hr {
        color: black;
    }

    .map {
        height: 200px;
        margin-bottom: 10px;
    }
}